<div class="container">
  <nav class="navbar navbar-expand-md navbar-light bg-faded">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      (click)="toggleCollapse()">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse t-justify-content-between" id="navbarNav" [ngStyle]="{display: isCollapsed ? 'none' : 'block'}">
          <!-- On the left side of our navbar we'll display the two links for public and private deals -->
          <ul class="nav navbar-nav mr-auto" *ngIf="authService.loggedIn()">
            <li class="nav-item">
              <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">Home</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/overview')">
              <a class="nav-link" routerLink="/overview" routerLinkActive="active">Dashboard</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/apoc')">
              <a class="nav-link" routerLink="/apoc" routerLinkActive="active">Apoc</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/diagnostic')">
              <a class="nav-link" routerLink="/diagnostic" routerLinkActive="active">Diagnostic</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/podium')">
              <a class="nav-link" routerLink="/podium" routerLinkActive="active">Podium</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/mpodium')">
              <a class="nav-link" routerLink="/mpodium" routerLinkActive="active" (click)="toggleCollapse()">Mobile Podium</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/mtransitions')">
              <a class="nav-link" routerLink="/mtransitions" routerLinkActive="active" (click)="toggleCollapse()">Transitions</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/brigadier')">
              <a class="nav-link" routerLink="/brigadier" routerLinkActive="active">Brigadier</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/persons')">
              <a class="nav-link" routerLink="/persons" routerLinkActive="active">Persons</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/vehicles')">
              <a class="nav-link" routerLink="/vehicles" routerLinkActive="active">Vehicles</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/keys')">
              <a class="nav-link" routerLink="/keys" routerLinkActive="active">Keys</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/locks')">
              <a class="nav-link" routerLink="/locks" routerLinkActive="active">Locks</a>
            </li>
            <li class="nav-item" *ngIf="showMenu('/areas')">
              <a class="nav-link" routerLink="/areas" routerLinkActive="active">Areas</a>
            </li>
            <li class="nav-item dropdown" *ngIf="showMenu('/system')" >
              <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">System</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown1">
                <li class="dropdown-item" *ngIf="showMenu('/companies')">
                  <a class="nav-link" routerLink="/companies" routerLinkActive="active">Companies</a>
                </li>
                <li class="dropdown-item" *ngIf="showMenu('/admins')">
                  <a class="nav-link" routerLink="/admins" routerLinkActive="active">Administrators</a>
                </li>
                <li class="dropdown-item" *ngIf="showMenu('/settings')">
                  <a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a>
                </li>
              </ul>
            </li>
            <li class="nav-item" *ngIf="showMenu('/reporting')">
              <a class="nav-link" href="reporting/">Reporting</a>
            </li>

            <li class="nav-item dropdown" *ngIf="showMenu('/debug') || showMenu('/transitions')" >
              <a class="dropdown-toggle nav-link" href="#" role="button" id="dropdown2" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">Debug</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown2">
                    <li class="nav-item" *ngIf="showMenu('/debug')">
                          <a class="nav-link" routerLink="/debug" routerLinkActive="active">Debug board</a>
                    </li>
                    <li class="nav-item" *ngIf="showMenu('/transitions')">
                          <a class="nav-link" routerLink="/transitions" routerLinkActive="active">Car Transitions</a>
                    </li>
              </ul>
            </li>
          </ul>

      <!-- On the right side of our navbar we'll display the login and logout actions depending on user state -->
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/" (click)="authService.signUp()" *ngIf="!authService.loggedIn()">Log In{{authService.authError()}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/" (click)="authService.logout()" *ngIf="authService.loggedIn()">Log Out{{authService.authError()}}</a>
        </li>
        <li class="nav-item">
          <span>1.0.12</span>
        </li>
      </ul>
      </div>
    </div>
  </nav>
  <div class="col-sm-12 content-area">
    <router-outlet></router-outlet>
  </div>
</div>
